<template>

  <div :class="'fI-slow mobile_' + isMobile()">

    <div id="loginWidget">
      <div class="pulsate loginWidgetText" >
        <div>~ <translate>FINAL STEPS</translate> ~</div>
      </div>
    </div>

    <div style="overflow: hidden;">

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <div style="width: 100%; overflow: hidden;">

        <FormulateForm
          v-model='values'
          :errors='errors'
          @submit.prevent="resetPassword()"
          #default="{ hasErrors }"
          v-if="!isConfirmed && !nextSteps"
          id="verifyAccountForm"
        >
          <div >
            <div v-if='errors.root' class='landingPageInput item-errors alert alert-danger'>
              <translate>Verify account error:</translate> {{ errors.root }}
            </div>

            <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper form-group">
              <div class="formText" style="padding: 0px 0px 20px 0;margin: auto;">
                <div v-translate>
                  Your email address has been <b>successfully verified</b>.
                </div>
                <div v-translate>
                  Please choose your password now.
                </div>
              </div>
              <FormulateInput
                class="landingPageInput"
                type="password"
                name="password"
                :placeholder="$gettext('password')"
                validation='required'
                style="margin-bottom: 5px;"
              />
              <FormulateInput
                class="landingPageInput"
                type="password"
                name="password_confirm"
                validation='required|confirm'
                :placeholder="$gettext('repeat password')"
                :validation-messages="{
                  confirm: $gettext('Passwords do not match.'),
                  required: $gettext('Password confirmation is required')
                }"
              />
            </div>
            <div class="logo_spacer_bottom"></div>
            <div class="form-group">
              <button v-if='hasErrors' disabled class="landingPageButton btn btn-primary trans" style="opacity: 0.3; cursor: not-allowed;"><i class="fas fa-save fas-left"></i><translate>SAVE PASSWORD</translate></button>
              <button v-else class="landingPageButton btn btn-success" @click.prevent="verifyAccount()"><i class="fas fa-save fas-left"></i><translate>SAVE PASSWORD</translate></button>
            </div>
          </div>
        </FormulateForm>

        <div v-if="isConfirmed && !nextSteps" style="width: 100%;" class="fI">
          <div class="logo_spacer_top"></div>
          <div class="loginFormWrapper fI-slow" style="padding: 28px 0 29px 0;" v-translate>
            Lastly, let’s <b>secure</b> your account via Two-Factor Authentication.
          </div>
          <div class="logo_spacer_bottom"></div>
          <div class="form-group">
            <button class="landingPageButton btn btn-success trans pulsate" @click.prevent="toSetup()"><i class="fas fa-lock fas-left"></i><translate>SETUP 2FA</translate></button>
          </div>
        </div>

        <div v-if="nextSteps" style="width: 100%;" class="fI">
          <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper fI-slow" style="padding: 28px 0 29px 0;">
              <b><translate>You got mail!</translate></b> <translate>Follow the instructions to set up the data-collection tool.</translate>
            </div>
          <div class="logo_spacer_bottom"></div>
          <span class="loginSubBtn w-50 mt-2 btn-small">
            <br>
            <translate>Issues?</translate><a href="https://t.me/farmgod_io" target="_blank"><b>&nbsp;<translate>Join our Telegram.</translate></b></a>
          </span>
        </div>

      </div>

    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { AUTH__REQUEST_ACCESS_TOKEN, AUTH__VERIFY_ACCOUNT } from '@/store/actions.type'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'
import { mapGetters } from 'vuex'
import { TWO_FACTOR_AUTH_REQUIRED } from '@/common/config'

import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

export default {
  name: 'VerifyAccount',
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter },
  data () {
    return {
      values: {
        password: '',
        password_confirm: ''
      },
      token: this.$route.query.token,
      isConfirmed: false,
      nextSteps: false,
      twoFactorAuthReq: TWO_FACTOR_AUTH_REQUIRED,
      errors: {}
    }
  },
  created () {
    if (this.errors) { this.errors[0] = null }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    verifyAccount () {
      this.$store
        .dispatch(AUTH__VERIFY_ACCOUNT, {
          password: this.values.password,
          password_verify: this.values.password_confirm,
          token: this.token
        })
        .then(() => {
          this.$store.dispatch(AUTH__REQUEST_ACCESS_TOKEN, {
            username: this.currentUser.email,
            password: this.values.password
          }).then(() => {
            // 2FA required?
            if (this.twoFactorAuthReq === 'no') {
              // this.$router.push({ name: 'overview' })
              this.nextSteps = true
            } else {
              this.isConfirmed = true
            }
          })
        })
        .catch((err) => {
          console.log(AUTH__VERIFY_ACCOUNT, err)
          this.handleErrors(err)
        })
    },
    handleErrors (err) {
      if (!err.response) {
        this.errors = { root: 'There was a server error, please check your connection' }
        return
      }
      if (parseInt(err.response.status) === 400) {
        this.errors = { root: err.response.data.detail }
      } else if (parseInt(err.response.status) === 422) {
        const errorsObject = { password: [], password_verify: [] }
        err.response.data.detail
          .map((err) => {
            errorsObject[err.loc[1]].push(err.msg)
          })

        // transform _verify to _confirm
        this.errors = { ...errorsObject, password_confirm: errorsObject.password_verify }
      } else {
        this.errors = { root: err.msg }
      }
    },
    toSetup () {
      this.$router.push({ name: '2fa_setup__choose_method' })
      console.log('Proceed to 2fa setup.')
    }
  }
}
</script>

<style>
  @import '../assets/style/sessions.scss';
</style>
